.aboutPage {
    display: flex;
    justify-content: space-between;
}

.aboutTextContainer {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: center;
}

.imageContainer {
    height: 100%;
    width: 30%;
    display: block;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-image: url('./pic-of-me.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.aboutTextSection {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.aboutText {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
}

.aboutButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50vw;
    height: 6vh;
    margin-top: 4%;
}

@media screen and (max-width: 960px) {

    .aboutPage{
        flex-direction: column;
        justify-content: center;
    }

    .imageContainer {
        height: 30%;
        width: 100%;
        background-size: contain;
        background-position: center;
    }

    .aboutTextContainer {
        margin-top: 5%;
        height: auto;
        width: 100%;
        align-items: center;
    }

}

@media screen and (max-width: 560px) {

    .aboutText {
        font-size: 10px;
        line-height: 20px;
    }

    .imageContainer {
        height: 20%;
    }

    .aboutTextContainer {
        height: auto;
    }

    .aboutButtonContainer {
        width: 80vw;
    }

}