@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem6YaGs126MiZpBA-UFUK0Zdc0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKWiUNhrIqM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
  
html {
      font-family: 'Open Sans';
      font-size: 16px;
}

.blackBack {
    background-color: #000;
    height: 100vh;
}

.page {
    position: absolute;
    top: 14vh;
    left: 0;
    right: 0;
    height: 86vh;
    background-color: #000;
}

.bold {
    font-weight: 700;
}

.invert {
    filter: invert(100%);
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: opacity 600ms ease-in 200ms;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
}

.fadeButton {
    background-color: #db2525;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    opacity: 0;
    animation: fadein 2s ease-in 3s;
    animation-fill-mode: forwards;
}

.fadeButtonProject {
    margin-top: 0;
    height: auto;
    width: 70vw;
    display: flex;
    justify-content: center;
}

.fadeButtonSkills {
    animation-delay: 2s;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.fadeButtonHome {
    position: absolute;
    left: 80%;
    top: 70%;
    width: 10%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fadeButtonAbout {
    margin-top: 0;
    height: auto;
    width: 20vw;
    display: flex;
    justify-content: center;
}

.fadeButtonAboutCv {
    margin-top: 0;
    height: auto;
    width: 20vw;
    display: flex;
    justify-content: center;
    animation: fadein 2s ease-in 2s;
    animation-fill-mode: forwards;
}

.fadeButton:hover {
    color: #db2525;
    background-color: white;
    cursor: pointer;
}

.normalButton {
    background-color: #db2525;
    color: white;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.normalButton:hover {
    color: #db2525;
    background-color: white;
    cursor: pointer;
}

.projectButton {
    width: 100%;
}

@keyframes fadein{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media screen and (max-width: 960px) {

    html {
        font-size: 14px;
    }

    .fadeButtonHome {
        position: absolute;
        left: 20%;
        top: 80%;
        width: 60%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media screen and (max-width: 560px) {

    html {
        font-size: 12px;
    }

    .fadeButtonAboutCv, .fadeButtonAbout {
        width: 30vw;
    }

}