nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 14vh;
    color: black;
    background-image: linear-gradient(180deg, #d3d3d3 90%, black 100%);
  }
  
  .navOptions {
    display: flex;
    width: 70%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin-right: 50px;
    color: black;
    font-weight: 700;
    gap: 32px;
  }
  
  .navOption {
    border-radius: 16px 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .notActive {
    color: black;
  }
  
  .notActive:hover {
    background-color: black;
    color: white;
  }
  
  .active {
    background-color: black;
    color: white;
  }
  
  .logoText {
    color: black;
    font-size: 2rem;
  }
  
  .logo {
    text-align: center;
    margin-left: 20px;
  }
  
  .logo:hover {
    cursor: pointer;
  }

  .burgerIcon {
    display: none;
  }

  @media screen and (max-width: 960px) {

    .navOptions {
      flex-direction: column;
      width: 100vw;
      height: 86vh;
      position: absolute;
      top: 14vh;
      left: -100%;
      transition: all 0.5s ease;
      z-index: 1000;
      margin-right: 0;
    }
  
    .activeNav {
      background-color: #d3d3d3;
      left: 0;
      transition: all 0.5s ease;
    }

    .navOption {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80%;
      height: 15%;
      border-radius: 16px;
    }

    .notActive {
      padding: 0;
    }
    
    .active {
      padding: 0;
    }

    .burgerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20vw;
      height: 100%;
      cursor: pointer;
    }

  }