.timelinePage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timelineContainer {
    width: 80vw;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.timelineDotsContainer {
    height: 10vh;
    display: flex;
    align-items: flex-end;
}

.timelineLinesContainer {
    display: flex;
    justify-content: space-between;
    margin: 8px;
    height: 24vh;
}

.timeline {
    width: 100%;
    height: 0.5vh;
    background-color: white;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timelineDot {
    border-radius: 50%;
    background-color: #db2525;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.longLine {
    width: 4px;
    height: 24vh;
    background-color: white;
}

.shortLine {
    width: 4px;
    height: 12vh;
    background-color: white;
}

.textboxContainer {
    width: 100%;
    height: 36vh;
    display: flex;
    justify-content: space-between;
}

.timelineSection {
    height: 30vh;
    width: 24vw;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.secondTimelineSection, .fourthTimelineSection {
    bottom: 12vh;
}

.firstTimelineSection {
    position: relative;
    right: 5vw;
}

.secondTimelineSection {
    position: relative;
    right: 1.25vw;
}

.thirdTimelineSection {
    position: relative;
    left: 1.25vw;
}

.fourthTimelineSection {
    position: relative;
    left: 5vw;
}

.timelineText {
    font-size: 12px;
    margin: 0 5%;
}

.timelineIcon {
    width: 40%;
    margin: 5%;
}

@media screen and (max-width: 960px) {

    .timelineContainer {
        width: 100vw;
        height: 76vh;
        flex-direction: row;
        align-items: center;
    }

    .timelineDotsContainer {
        height: 100%;
        min-width: 10vw;
        align-items: center;
        justify-content: flex-end;
    }

    .timeline {
        width: 4px;
        height: 80%;
        flex-direction: column;
    }

    .timelineLinesContainer {
        flex-direction: column;
        height: calc(80% - 16px);
        width: 16vw;
    }

    .longLine, .shortLine {
        width: 16vw;
        height: 4px;
    }

    .textboxContainer {
        width: 66vw;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .timelineSection {
        width: 95%;
        height: 15vh;
        position: static;
    }

    .firstTimelineSection, .secondTimelineSection {
        right: 0;
    }
    
    .thirdTimelineSection, .fourthTimelineSection {
        left: 0;
    }

    .timelineText {
        font-size: 10px;
    }
    
    .timelineIcon {
        width: auto;
        height: 10%;
        margin: 2%;
    }

    .fadeButtonExperienceMobile {
        position: absolute;
        top: 79vh;
        width: 80vw;
        left: 10vw;
    }

}

@media screen and (max-width: 560px) {

    .timelineText {
        font-size: 8px;
    }

}